<template>
  <v-app id="inspire">
    <v-app-bar
      app
      clipped-right
      color="white"
      height="100"
      id="appbarIdentifier"
    >
      <!-- :height="!scrolled ? 100 : 70" -->
      <div class="left-second-rects" :style="second"></div>
      <div class="left-first-rect" :style="first"></div>
      <div class="right-first-rect" :style="rightFirst"></div>
      <v-toolbar-title style="width: 100%">
        <div
          class="text-center app-bar-logo"
          :style="`right:${this.logoPos}px;top:26px`"
        >
          <!-- ${scrolled ? 16 : 26} -->
          <img
            src="../assets/logo.svg"
            :style="`height: 38px; position: relative; top: 4px`"
            alt=""
          />
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div class="admin-container">
      <h1>VŠEOBECNÉ OBCHODNÍ PODMÍNKY PRO INTERNETOVOU APLIKACI MYMD21</h1>
      <p class="mt-8">
        Vzájemná práva a povinnosti Uživatele a Provozovatele pramenící z
        užívání Aplikace se řídí těmito obchodními podmínkami:
      </p>

      <h4 class="mt-10 mb-4">1. DEFINICE</h4>

      <p>1.1. V těchto obchodních podmínkách:</p>

      <p>
        1.1.1. <strong>„Provozovatel“</strong> je společnost MY-MD21 CZ s.r.o.,
        náměstí 14. října 1307/2, 150 00 Praha, IČ: 08577781
      </p>

      <p>
        1.1.2. <strong>„Aplikace“</strong> znamená internetovou aplikaci s
        názvem MY-MD21, mezi jejíž hlavní funkcionality patří Sdružování a
        prezentace informací o vývoji projektu a která je dostupná z internetové
        adresy www.app-mymd21.cz.
      </p>

      <p>
        1.1.3. <strong>„VOP“</strong> znamená tyto Všeobecné obchodní podmínky.
      </p>

      <p>
        1.1.4. <strong>„Smlouva“</strong> znamená smlouvu uzavřenou mezi
        Uživatelem a Provozovatelem, jejímž předmětem je poskytnutí Licence k
        užívání Aplikace Uživateli za podmínek uvedených v těchto VOP.
      </p>

      <p>
        1.1.5. <strong>„Licence“</strong> znamená nevýhradní licenci k užívání
        Aplikace za podmínek uvedených v těchto VOP.
      </p>

      <p>
        1.1.6. <strong>„Uživatel“</strong> znamená jakoukoliv právnickou nebo
        fyzickou osobu odlišnou od Provozovatele, která užívá Aplikaci.
      </p>

      <p>
        1.1.7. <strong>„Přihlašovací údaje“</strong> znamenají unikátní
        kombinaci přihlašovacího jména Uživatele a hesla zvoleného Uživatelem,
        které Uživatel uloží do databáze Aplikace při zřizování Uživatelského
        účtu prostřednictvím Aplikace a/nebo Uživateli automaticky
        vygenerovaného Aplikací.
      </p>

      <h4 class="mt-10 mb-4">
        2. PROCES UZAVŘENÍ SMLOUVY O VYUŽÍVÁNÍ APLIKACE
      </h4>

      <p>
        2.1. Aplikace bude pro Uživatele zpřístupněna po podpisu a splnění
        podmínek Smlouvy o poskytnutí služeb a oprávnění k užití aplikace
        MYMD21.
      </p>

      <p>
        2.2. Kliknutí Uživatele na tlačítko „Souhlasím“ je bezvýhradným přijetím
        návrhu na uzavření Smlouvy ze strany Uživatele a tímto úkonem je
        uzavřena Smlouva.
      </p>

      <p>
        2.3. Kliknutím na tlačítko ve smyslu 2.2 rovněž Uživatel vyjadřuje
        souhlas s těmito VOP a Provozovatel se o tom Uživatele zavazuje
        informovat v uživatelském prostředí Aplikace.
      </p>

      <p>
        2.4. Uzavřením Smlouvy vstupují v účinnost následující ustanovení těchto
        VOP.
      </p>

      <h4 class="mb-4 mt-10">3. UŽITÍ APLIKACE</h4>

      <p>
        3.1. Provozovatel uděluje Uživateli Licenci v rozsahu a za podmínek
        uvedených v uživatelském prostředí Aplikace, zejména uvedených u daného
        typu Uživatelského účtu.
      </p>

      <p>
        3.2. Provozovatel má právo poskytnout plnění Smlouvy (např. zpřístupnit
        danou část nebo funkcionalitu Aplikace) až v momentě, kdy dojde ke
        splnění podmínek daných Smlouvou o poskytnutí služeb a oprávnění k užití
        aplikace MYMD21.
      </p>

      <p>
        3.3. Provozovatel je po uplynutí doby Licence oprávněn plně a/nebo
        částečně znepřístupnit Uživatelský účet Uživateli.
      </p>

      <p>3.4. Uživatel nesmí zřídit více než jeden Uživatelský účet.</p>

      <p>
        3.5. Uživatel nemá právo udělit třetí osobě podlicenci k užívání
        Aplikace.
      </p>

      <p>
        3.6. Veškerá finanční plnění realizovaná prostřednictví Aplikace nebo
        platební brány propojené s Aplikací se považují za zaplacené připsáním
        celé příslušné částky na příslušný bankovní účet Provozovatele.
      </p>

      <p>
        3.7. Provozovatel má právo Aplikaci nebo její část kdykoliv odstavit,
        změnit a/nebo ji znepřístupnit z příslušné internetové adresy, a to z
        jakéhokoliv důvodu.
      </p>

      <p>
        3.8. Uživatel má právo na užívání Aplikace za podmínek Smlouvy v
        aktuální verzi dostupné z příslušné internetové adresy.
      </p>

      <p>
        3.9. Uživatel se zavazuje při užívání Aplikace jednat tak, aby jemu ani
        Provozovateli užíváním Aplikace nevznikla žádná škoda.
      </p>

      <h4 class="mb-4 mt-10">4. DATABÁZE</h4>

      <p>
        4.1. Uživatel nemá právo prostřednictvím Aplikace strojově (zejm. pomocí
        tzv. softwarových robotů) vytěžovat databázi spojenou s Aplikací.
      </p>

      <p>
        4.2. 4.2 Uživatel a Provozovatel tímto sjednávají, že jakékoliv údaje
        vložené Uživatelem do databáze Aplikace jsou části databáze shromážděné
        Provozovatelem a stávají se součástí databáze Provozovatele, aniž by
        Uživateli jeho jednáním popsaným v tomto odstavci vzniklo jakékoliv
        právo k databázi Aplikace.
      </p>

      <p>
        4.3. Smluvní strany jsou si vědomy, shodně prohlašují a činí nesporným,
        že Aplikace splňuje podmínky ust. § 562 odst. 2 občanského zákoníku,
        tedy že záznamy údajů v Aplikaci a její databázi jakožto elektronickém
        systému jsou spolehlivé a jsou prováděny systematicky a posloupně a jsou
        chráněny proti změnám.
      </p>

      <h4 class="mb-4 mt-10">5. ODPOVĚDNOST ZA ŠKODU</h4>

      <p>5.1. Ustanovení toho článku VOP se nevztahují na spotřebitele.</p>

      <p>
        5.2. Uživatel se tímto výslovně vzdává práva na náhradu škody od
        Provozovatele neúmyslně nebo nikoli z hrubé nedbalosti způsobené
        Provozovatelem porušením jakékoliv povinnosti Provozovatele uvedené ve
        Smlouvě v souvislosti s plněním Smlouvy nebo v těchto VOP.
      </p>

      <h4 class="mb-4 mt-10">6. ZMĚNA VOP</h4>

      <p>
        6.1. Uživatel bere na vědomí, že Provozovatel uzavírá Smlouvu v běžném
        obchodním styku s větším počtem osob a Smlouvy jsou podle svého
        charakteru zavazující dlouhodobě k opětovným plněním stejného druhu s
        odkazem na tyto VOP. Smluvní strany činí nesporným, že z povahy závazků
        Provozovatele podle těchto VOP vyplývá a již před uzavřením Smlouvy
        vyplývala rozumná potřeba pozdější změny těchto VOP.
      </p>

      <p>
        6.2. Smluvní strany tímto ujednávají, že Provozovatel může tyto VOP v
        přiměřeném rozsahu změnit, a zároveň ujednávají, že změna VOP bude
        Uživateli, jako druhé straně, oznámena prostřednictvím e-mailové zprávy
        odeslané na e- mailovou adresu zadanou Uživatelem do Aplikace. Uživatel
        má v takovém případě právo změny VOP odmítnout a předmětný závazek mezi
        ním a Provozovatelem z tohoto důvodu vypovědět ve výpovědní době jeden
        (1) kalendářní měsíc, která začne běžet den následující po odeslání
        oznámení, o které Smluvní strany tímto shodně prohlašují, že je
        dostatečná k obstarání obdobných plnění od jiného subjektu.
      </p>

      <p>
        6.3. V případě, že dojde k uzavření nové Smlouvy k již existujícímu
        Uživatelskému účtu (tj. k obnovení nebo prodloužení Uživatelského účtu),
        řídí se taková Smlouva VOP ve znění ke dni uzavření takové Smlouvy.
      </p>

      <h4 class="mb-4 mt-10">7. OCHRANA OSOBNÍCH ÚDAJŮ</h4>

      <p>
        7.1. Provozovatel je ze zákona povinen chránit a zabezpečit poskytnuté
        osobní údaje. Provozovatel proto využívá různých efektivních
        zabezpečovacích technologií k tomu, aby byly osobní údaje ochráněny před
        neoprávněným zpřístupněním nebo užitím.
      </p>

      <p>
        7.2. Podrobnější informace o ochraně osobních údajů naleznete v Zásadách
        ochrany osobních údajů, které tvoří přílohu 1 této smlouvy.
      </p>

      <h4 class="mt-10 mb-4">8. POVINNÉ INFORMACE PRO SPOTŘEBITELE</h4>

      <p>
        8.1. Tento článek VOP podmínek je účinný pouze vůči Uživateli, který je
        spotřebitelem. Ustanovení uvedená v tomto článku VOP se nevztahují
        zejména na podnikatele – živnostníky nebo obchodní společnosti.
      </p>

      <p>8.2. Provozovatel tímto informuje Uživatele, že:</p>

      <p>
        8.2.1. adresa pro doručování písemností Provozovateli je shodná s výše
        uvedenou adresou sídla Provozovatele;
      </p>

      <p>8.2.2. telefonický kontakt Provozovatele je +420 606 864 199;</p>

      <p>
        8.2.3. adresa Provozovatele pro doručování elektronické pošty je
        info@mymd21.cz;
      </p>

      <p>
        8.2.4. Uživatel je povinen zaplatit Provozovateli odměnu za Licenci,
        není-li Licence poskytována bezúplatně, jedním ze způsobů, který je
        uveden ve Smlouvě o poskytnutí služeb a oprávnění k užití aplikace
        MYMD21;
      </p>

      <p>8.2.5. Uživateli nevznikají náklady na dodání;</p>

      <p>
        8.2.6. k užívání Aplikace je třeba přístup k síti Internet a internetový
        prohlížeč Google Chrome, Mozilla Firefox, Safari, Opera;
      </p>

      <p>
        8.2.7. Uživatel má právo od Smlouvy odstoupit bez uvedení důvodu a bez
        jakékoliv sankce do 5 dnů od převzetí plnění. Převzetím plnění se rozumí
        poskytnutí Licence;
      </p>

      <p>
        8.2.8. ve vztahu k Provozovateli nevznikají Uživateli žádné náklady za
        použití komunikačních prostředků na dálku;
      </p>

      <p>
        8.2.9. údaje o uzavření Smlouvy vč. těchto obchodních podmínek jsou
        uloženy v databázi Aplikace a Uživatel k nim má přístup prostřednictvím
        Uživatelského účtu.
      </p>

      <p>
        8.2.10. Uživatel má možnost zjistit chyby kontrolou v Uživatelském účtu;
      </p>

      <p>
        8.2.11. opravování chyb vzniklých při zadávání dat lze provádět rovněž
        prostřednictvím Aplikace a tam, kde to Aplikace neumožňuje,
        prostřednictvím technické podpory, jejíž kontakt je uveden v Aplikaci;
      </p>

      <p>
        8.2.12. Provozovatel dodržuje všechny platné zákony České republiky;
        žádné další kodexy nejsou pro Provozovatele závazné;
      </p>

      <p>
        8.2.13. Provozovatel nevyužívá žádné prostředky mimosoudního řešení
        sporů;
      </p>

      <p>
        8.2.14. předmětem Smlouvy není dodání zboží, na Aplikace se tak
        nevztahují ustanovení zákona o reklamaci; odpovědnost za zjevné nebo
        skryté vady Aplikace, které měla Aplikace v době jejího zpřístupnění
        Uživateli, lze uplatnit u Provozovatele za podmínek stanovených zákonem,
        Smlouvou a těmito VOP;
      </p>

      <p>
        8.2.15. Uživatel je povinen dodržovat tyto VOP, které jsou též součástí
        Smlouvy, a platné a účinné právní předpisy České republiky;
      </p>

      <p>
        8.2.16. Uživatel se může obrátit se stížností na orgán dohledu nebo
        státního dozoru. Česká obchodní inspekce vyřizuje mimosoudní stížnosti
        spotřebitelů způsobem a za podmínek stanovených příslušnými právními
        předpisy.
      </p>

      <h4 class="mt-10 mb-4">9. ROZHODNÉ PRÁVO</h4>

      <p>
        9.1. Tyto VOP, jakož i Smlouva, se řídí právním řádem České republiky,
        zejména zák. č. 89/2012 Sb., občanský zákoník, ve znění pozdějších
        předpisů.
      </p>

      <p>
        9.2. Jakékoliv spory vzniklé na základě Smlouvy a/nebo těchto VOP budou
        rozhodovat věcně a místně příslušné soudy České republiky.
      </p>
      <p class="mt-8">Tyto VOP nabývají platnosti a účinnosti dne 01.02.2023</p>

      <h2 class="mt-10 mb-8">
        ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ PRO INTERNETOVOU APLIKACI MYMD21
      </h2>
      <p>Souhlas se zpracováním osobních údajů udělujete:</p>
      <table>
        <tr>
          <td>
            <strong>Subjekt: </strong>
          </td>
          <td>MY-MD21 CZ s.r.o.</td>
        </tr>
        <tr>
          <td>Zastoupený:</td>
          <td>Tomášem Vychytilem a Ing. Tomáše Kubátem, jednateli</td>
        </tr>
        <tr>
          <td>Se sídlem:</td>
          <td>náměstí 14. října 1307/2, Smíchov, 150 00 Praha 5</td>
        </tr>
        <tr>
          <td>Bankovní spojení:</td>
          <td>8354882/0800</td>
        </tr>
        <tr>
          <td>IČO:</td>
          <td>08577781</td>
        </tr>
        <tr>
          <td>DIČ:</td>
          <td>CZ 08577781</td>
        </tr>
        <tr>
          <td>Zapsaný:</td>
          <td>
            v obchodním rejstříku u Městského soudu v Praze, v oddíle C, vložka
            321313
          </td>
        </tr>
      </table>
      <p class="mt-8">
        aby v souladu se zákonem o ochraně osobních údajů a nařízení GDPR
        zpracovávala jako správce osobní údaje dle podmínek níže. Při nakládání
        s osobními údaji postupujeme v souladu s právním řádem České republiky a
        přímo použitelnými předpisy Evropské unie, zejména se zákonem č.
        110/2019 Sb., o zpracování osobních údajů, ve znění pozdějších předpisů,
        zákonem č. 480/2004 Sb. a v souladu s ustanovením čl. 13 Nařízení
        Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016,
        obecného nařízení o ochraně osobních údajů o některých službách
        informační společnosti a o změně některých zákonů, ve znění pozdějších
        předpisů. V následujících řádcích vám přiblížíme, jakým způsobem a za
        jakým účelem zpracováváme vaše osobní údaje a kdo mohou být jejich
        zpracovatelé. Tyto informace jsou důležité, proto doufáme, že si najdete
        čas a pečlivě si vše přečtete.
      </p>
      <h4 class="mt-10 mb-4">1. JAKÉ ÚDAJE JAKO SPRÁVCI ZPRACOVÁVÁME</h4>

      <p>1.1. Jméno</p>
      <p>1.2. Příjmení</p>
      <p>1.3. E-mailová adresa</p>

      <h4 class="mt-10 mb-4">
        2. KDY NENÍ POTŘEBA DÁVAT SOUHLAS SE ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ?
      </h4>

      <p>
        Osobní údaje můžeme zpracovávat i bez vašeho souhlasu pouze za těmito
        účely:
      </p>

      <p>
        2.1. Plnění smlouvy jejímž předmětem je poskytnutí služby nebo produktu.
      </p>
      <p>
        2.2. Plnění právních povinností, které pro nás vyplývají z obecně
        závazných právních předpisů.
      </p>
      <p>
        2.3. Zpracování v rámci oprávněného zájmu (např. k přímému marketingu,
        zajištění bezpečnosti a ochrany života).
      </p>

      <h4 class="mt-10 mb-4">
        3. ZA JAKÝM ÚČELEM OSOBNÍ ÚDAJE SPRAVUJEME A JAK DLOUHO?
      </h4>

      <p>
        Údaje mohou být využity k obchodním a marketingovým účelům, tj. k vedení
        databáze uživatelů webové stránky/aplikace a k nabízení zboží a služeb,
        a to po dobu trvání právního důvodu pro tento způsob zpracování a
        využití osobních a dalších údajů. Jinými slovy Vaše osobní údaje
        shromažďujeme za účelem možnosti využívat naše služby a informovat vás o
        novinkách. Přečtěte si více, za jakým účelem spravujeme jednotlivé
        údaje.
      </p>
      <p>
        Osobní údaje zpracováváme za účelem plnění smlouvy, tedy za účelem
        dodání zboží nebo poskytnutí služby. Dále můžeme tyto údaje zpracovávat
        za účelem splnění našich zákonem stanovených povinností (zejména
        evidenční povinnosti, archivace daňových dokladů apod.) a pro účely
        ochrany našich právních nároků.
      </p>
      <p>
        Identifikační a adresní údaje můžeme využít v souladu s tzv. oprávněným
        zájmem pro tvorbu interních statistik. Anonymizované výstupy z těchto
        statistik využíváme za účelem zlepšování našich služeb včetně analýzy
        chování uživatelů a marketingu. Osobní údaje uživatele zpracováváme od
        zaregistrování ve Službě, po dobu platnosti registrace. Veškeré
        poskytnuté osobní údaje za výše uvedenými účely jsou poskytované zcela
        dobrovolně a vyjádřením jejich souhlasu probíhá pomocí aktivní interakce
        (zaškrtnutí) políčka se souhlasem pro jejich zpracování nebo na základě
        oprávněného zájmu. Zpracování údajů za tímto můžete kdykoliv odvolat
        prostřednictvím e-mailu.
      </p>

      <h4 class="mt-10 mb-4">
        4. JAKÁ JSOU MOJE PRÁVA V SOUVISLOSTI S OCHRANOU OSOBNÍCH ÚDAJŮ?
      </h4>

      <p>
        4.1. Požadovat po nás informaci, které Vaše osobní údaje zpracováváme.
      </p>
      <p>4.2. Požadovat po nás vysvětlení ohledně zpracování osobních údajů.</p>
      <p>
        4.3. Vyžádat si u nás přístup k těmto údajům a tyto nechat aktualizovat
        nebo opravit.
      </p>
      <p>4.4. Požadovat po nás výmaz těchto osobních údajů.</p>
      <p>
        4.5. Právo být informován o porušení zabezpečení osobních údajů v
        určitých případech.
      </p>
      <p>
        Můžete kdykoliv podat stížnost týkající se zpracování vašich osobních
        údajů nebo neplnění povinností správce plynoucích z GDPR k dozorovému
        úřadu. Dozorovým úřadem je v ČR Úřad pro ochranu osobních údajů, se
        sídlem Pplk. Sochora 27, 170 00 Praha 7, www.uoou.cz.
      </p>

      <h4 class="mt-10 mb-4">
        5. AKTUALIZACE ZÁSAD PRO OCHRANU OSOBNÍCH ÚDAJŮ
      </h4>

      <p>
        5.1. Průběžně můžeme Zásady ochrany osobních údajů upravovat či
        aktualizovat. Jakékoliv změny těchto Zásad ochrany osobních údajů se
        stanou účinnými po jejich zveřejnění na této stránce.
      </p>
      <p>5.2. Tyto podmínky nabývají účinnosti dnem 01. 02. 2023.</p>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      logoPos: null,
    };
  },
  mounted() {
    if (document) {
      this.size = window.innerWidth;
      this.logoPos = (window.innerWidth - 127) / 2;
      window.addEventListener('resize', this.handleResize);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    bus.$off('refresh_sections');
    console.debug('Destroyed');
  },
  async created() {},
  methods: {
    handleResize() {
      if (document) {
        this.size = window.innerWidth;
        this.logoPos = (window.innerWidth - 127) / 2;
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    first() {
      return {
        backgroundImage: `url('${require('@/assets/first_rect.svg')}')`,
      };
    },
    rightFirst() {
      return {
        backgroundImage: `url('${require('@/assets/first_right.svg')}')`,
      };
    },
    rightSecond() {
      return {
        backgroundImage: `url('${require('@/assets/second_right.svg')}')`,
      };
    },
    second() {
      return {
        backgroundImage: `url('${require('@/assets/second_rects.svg')}')`,
      };
    },
  },
};
</script>

<style></style>
